import React from 'react';
import ResultImageFooter from './ResultImageFooter'

export default ({resultImage, onRetry}) => {
    
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '600px', // Max width of the entire component
        margin: 'auto'
    };

    const frameStyle = {
        position: 'relative',
        width: '100%',     // Takes the full width of its parent (containerStyle)
        maxWidth: '90%',   // Ensure it doesn't grow beyond 90% of its parent's width
        paddingBottom: '90%', // Maintain aspect ratio
    };

    const resultImageStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        zIndex: 1
    };
    

    return (
        <div>
        <div style={containerStyle}>
            <div style={frameStyle}>
                {resultImage && <a href={resultImage} target="_blank" rel="noopener noreferrer" ><img src={resultImage} alt="Result" style={resultImageStyle} /></a>}
            </div>
        </div>
        <ResultImageFooter onRetry={onRetry} imageUrl={resultImage}/>
        </div>
    );
};
