import React from 'react';
import Header from './Header';
import UploadForm from './UploadForm';
import AboutSection from './AboutSection';
import backgroundImage from './background1.jpg';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import DonateSection from './DonateSection';
import Party from './Party';
import StickyHeader from './StickyHeader';

function App() {
  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    direction: 'rtl',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1  // Sit behind the content
};

  return (
    <ThemeProvider theme={theme}>
      <StickyHeader/>
      <div style={{ position: 'relative' }}> 
        <div style={backgroundStyle}></div>
        <div>
          <Header />
          <AboutSection />
          <UploadForm /> {/* Use the new component */}
          <Party/>
          <DonateSection/>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
