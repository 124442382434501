import React from 'react';
import { Card, CardContent, CardActions, Typography, Button } from '@mui/material';

const payboxLink = "https://payboxapp.page.link/KJDuzb3z6jAjn3gc8"

const MyButton = () => <CardActions style={{ justifyContent: 'center' }}>
<a href={payboxLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <Button size="large" variant="contained" color="primary">
    לתמיכה מעומק הלבלב
    </Button>
</a>
</CardActions>

export default () => {
  return (
    <a href={payboxLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <Card style={{ margin: '2rem auto', direction:"rtl", textAlign:'center', maxWidth: '1000px'}}>
      <CardContent>
        <Typography variant="h3" component="div">
        האחיות מספריים
        </Typography>
        <Typography variant="h4" component="div">
        סלון היופי הלוהט ביותר בעיר מידברן
        </Typography>
        <Typography variant="h6" >
        משנת 2017 ועד היום, אנחנו פועלות לדיגום הפריזורה של שבורות הפלאייה.
בכל יום בין 14-16 אנחנו פותחות את שערי המספריים של סלון היופי למסיבות צהריים לוהטות ומלהיטות. זו השעה שבה את תופסת את עצמך בציפורניים, עולה על מנומר ובאה לטבוע איתנו בורוד.
        </Typography>
        <Typography variant="h4" component="div" style={{marginTop: '25px'}}>
        במה נשקיע את הכסף שאנחנו מגייסות?
        </Typography>
        <Typography variant="h6" component="div" style={{marginBottom: '15px'}}>
        (בערך לפי סדר החשיבות וכמות האותיות בשורה)
        </Typography>
        <Typography variant="h6" component="div">💄 אודם 💄</Typography>
        <Typography variant="h6" component="div">🍭 צמר גפן ורוד 🍭</Typography>
        <Typography variant="h6" component="div">💇‍♀️ מכונת תספורת חדשה 💇‍♀️</Typography>
        <Typography variant="h6" component="div">💅 שדרוג עמדת "שימי לק! בגלל המדע" 💅</Typography>
        <Typography variant="h6" component="div">🧴 שמן קוקוס במקום שמן תינוקות (לעמדת מסאז'ים) 🧴</Typography>
        <Typography variant="h6" component="div">🍸 הפעלת הבר האלכוהולי שלנו - "ברברת" לרווחת הלקוחות (זאת את!) 🍸</Typography>
        <Typography variant="h6" component="div">🚕 השקת "גט סקסי!" - הארט קאר של האחיות שמסדר לך את הלוק גם כשאת בריחוק 🚕</Typography>
        <Typography variant="h6" component="div">✂️ לייזר ורוד שעושה מספריים בשמיים כדי שתוכלי לדעת בדיוק איך להגיע אלינו מכל נקודה בפלאייה ✂️</Typography>

      </CardContent>
      <MyButton/>
    </Card></a>
  );
};