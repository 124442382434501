// AboutSection.js

import { Typography } from '@mui/material';
import React from 'react';

const AboutSection = () => {

  const styles = {
    aboutSection: {
      textAlign: 'center',
      backgroundColor: 'rgba(255, 105, 180, 0.7)', // Semi-transparent hot pink
      border: '2px solid #FF69B4',  // Hot pink
      padding: '20px',
      margin: '20px',
      borderRadius: '10px',
      direction: 'rtl'
    },
    stepsContainer: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    step: {
      width: '30%'
    }
  };

  return (
    <div style={styles.aboutSection}>
      <Typography variant='h3'>הפכי לאחות</Typography>
      <Typography variant='h6' style={{marginBottom: '30px'}}>בשתי שניות או פחות!</Typography>
      <div style={styles.stepsContainer}>
        <div style={styles.step}>
          <Typography variant="h4">צעד ראשון</Typography>
          <Typography>מעלה תמונה עם פרצוף ברור</Typography>
          <p></p>
          <Typography>זה בסדר גמור אם התמונה כיעור</Typography>
        </div>
        <div style={styles.step}>
        <Typography variant="h4">צעד שני</Typography>
          <Typography>מייקאובר מקצה לקצה</Typography>
          <p></p>
          <Typography>את בידיים טובות</Typography>
        </div>
        <div style={styles.step}>
        <Typography variant="h4">צעד שלישי</Typography>
          <Typography>מאמי מוכנה למסיבה</Typography>
          <p></p>
          <Typography>לכי תספרי לכולן!</Typography>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
