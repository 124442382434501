// MainComponent.js
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import { Box, Snackbar } from '@mui/material';
import RetryIcon from '@mui/icons-material/Refresh';
import ShareIcon from '@mui/icons-material/Share';

const homeUrl = process.env.REACT_APP_HOME_URL || 'https://scissor-sisters.hair/'

const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


const ensureHttps = url => {
  if (!url) {
      return '';
  }

  // If the URL already starts with "https", return it as is
  if (url.startsWith('https://')) {
      return url;
  }

  // If the URL starts with "http", replace it with "https"
  if (url.startsWith('http://')) {
      return url.replace('http://', 'https://');
  }
  if (url.startsWith('data')) {
    console.log('returning: ' + url)
    return url
  }
  // If the URL doesn't start with any protocol, prepend "https://"
  return 'https://' + url;
}

export default ({ onRetry, imageUrl }) => {
  const [urlCopied, setUrlCopied] = useState(false);
  // Function to handle the "Share" button click
  const handleShare = async () => {
    const response = await fetch(ensureHttps(imageUrl));
    const data = {
      title: 'הסלון הוירטואלי של האחיות מספריים',
      url: homeUrl
    }
    if (isIOS()) {
      data['text'] = homeUrl
    } else {
      // here image is url/location of image
      const blob = await response.blob();
      const file = new File([blob], 'share.jpg', {type: blob.type});
      data['files'] = [file]
    }
    // feature detecting navigator.canShare() also implies
    // the same for the navigator.share()
    if (!navigator.canShare) {
      navigator.clipboard.writeText(homeUrl)
      console.log("Whould have shared:")
      console.log(data)
      setUrlCopied(true)
      return;
    }
    if (navigator.canShare(data)) {
      navigator.share(data).catch((error) => console.error('Share failed:', error));
    } else {
        console.warn("error sharing")
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={2}
      style={{'margin-top': '5px', 'direction': 'rtl'}}
    >
      {/* Retry Button */}
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<RetryIcon style={{marginLeft: '5px'}}/>}
        onClick={onRetry}
      >
        {/* Your Hebrew text for "Retry" */}
        רגע רגע עוד פעם
      </Button>

      {/* Share Button */}
      <Button
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<ShareIcon style={{marginLeft: '5px'}}/>}
        onClick={handleShare}
      >
        {/* Your Hebrew text for "Share" */}
        רצה לספר לחבר׳ה
        {isIOS() && <span>(לחיצה ארוכה על התמונה בשביל לשתף אותה)</span>}
      </Button>
      <Snackbar
        open={urlCopied}
        autoHideDuration={3000}
        onClose={() => setUrlCopied(false)}
        message="הכתובת נשמרה בקליפבורד"
      />
    </Box>
  );
}
