import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF69B4',  // Hot Pink
    },
    secondary: {
      main: '#D4AF37',  // Leopard Gold
    },
    text: {
      primary: '#000000',  // Black
      secondary: '#FFFFFF'  // White
    },
    accent: {
      main: '#00FFFF',  // Aqua Blue
    }
  },
  typography: {
    fontFamily: '"Rubik", sans-serif',
    h1: {
      fontFamily: '"Aharoni-Bold", sans-serif',
    },
    h2: {
      fontFamily: '"Aharoni-Bold", sans-serif',
    },
    h3: {
      fontFamily: '"Aharoni-Bold", sans-serif',
    },
    h4: {
      fontFamily: '"Aharoni-Bold", sans-serif',
    },
    h5: {
      fontFamily: '"Aharoni-Bold", sans-serif',
    },
    // ... Add other styling here
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundImage: 'url("/path/to/leopard-print.jpg")',  // Replace with your leopard print image path
          color: '#FFFFFF',  // White text
        },
      },
    },
    // Any other component customizations can go here
  },
  overrides: {
    MuiCssBaseline: {
      styleOverrides: {
        // Custom class
        ".titleFont": {
          fontFamily: '"Aharoni-Bold", sans-serif',
        },
      },
    }
  }
});

export default theme;
