import React, { useState, useEffect } from "react";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import OnegShabat from './oneg_shabat.png'

const eventbuzzLink = "https://eventbuzz.co.il/lp/event/oneg-shabat"

const StickyHeader = () => {
  return (
    <AppBar 
      position="sticky"
      sx={{direction: 'rtl', backgroundColor: '#F8029C'}}
    >
      <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
        <a href={eventbuzzLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button size="large" variant="contained" color="secondary"  >
                כרטיס
            </Button>
        </a>
        <img src={OnegShabat}/>
        <div>
          <Typography variant="h6" style={{fontSize: '1rem'}}>עקבו אחרינו</Typography>
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label="facebook" 
            href="https://www.facebook.com/groups/1641653902822343/user/100067105532126/" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{display: 'inline'}}
          >
            <FacebookIcon style={{fontSize: 30}}/>
          </IconButton>
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label="instagram" 
            href="https://www.instagram.com/scissors.sistas/" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{display: 'inline'}}
          >
            <InstagramIcon style={{fontSize: 30}} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default StickyHeader;
