import React, { useState } from 'react';
import axios from 'axios';
import { Button, FormControl, InputLabel, Select, MenuItem, Typography, CircularProgress, Grid, Radio, RadioGroup, FormLabel, FormControlLabel, Card,
  CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import ResultImage from './ResultImage';

axios.defaults.timeout = 40000;

const backendUrls = (process.env.REACT_APP_BACKEND_URL || "https://e7f4-5-182-59-83.ngrok-free.app,https://46da-2a06-c701-4c00-4900-2022-266f-f896-78a4.ngrok-free.app").split(',')

const useStyles = makeStyles(() => ({
  card: {
    textAlign: 'center',
    minHeight: '250px'
  },
  formControl: {
    margin: '16px',
    width: '100%'
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  label: {
    fontSize: '1.5rem',
  },
  formLabel: {
    marginBottom: '1rem'
  },
  previewChip: {
    minWidth: 'auto',
    maxWidth: '100%',
    // Centering the image preview
    margin: 'auto',
    justifyContent: 'center'
  },
  dropzoneText: {
    fontFamily: '"Rubik", sans-serif !important',
  }
}));


const UploadForm = () => {
  const classes = useStyles();
  const [gender, setGender] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);

  const handleFileChange = (files) => {
    setSelectedFile(files[0]);
  };

  const onRetry = () => setResultImage(null)

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsServerError(false)
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('gender', gender);

    let remainingServers = [...backendUrls]
    let success = false
    while (remainingServers.length && !success) {
      // const randomIndex = Math.floor(Math.random() * remainingServers.length);
      // const [backendUrl] = remainingServers.splice(randomIndex, 1);
      const [backendUrl] = remainingServers.splice(0, 1);
      try {
        const response = await axios.post(`${backendUrl}/swap_face`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Bypass-Tunnel-Reminder': 'yes'
          }
        });
    
        if (response.data && response.data.imageUrl) {
            setResultImage(response.data.imageUrl);
            success=true
        } else {
            console.error("Unexpected response format from server.");
        }
      } catch (err) {
        console.error("Error swapping faces: ", err);
      }
    }
    if (!success) {
      setIsServerError(true)
    }
    setIsLoading(false);
} ;


  return (
    <form style={{ margin: '1em', direction: 'rtl' }}>
      <Grid container spacing={3} style={resultImage ? {display: 'none'} : {}}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardContent>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={`${classes.formLabel}`} style={{color: "#FF69B4", fontSize: "30px", top: '6px', marginBottom: '20px'}}>
                  אח או אחות?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  value={gender}
                  row
                  onChange={(e) => setGender(e.target.value)}
                  className={classes.radioGroup}
                >
                  <FormControlLabel 
                    value="m" 
                    control={<Radio style={{color: "#D4AF37", transform: 'scale(1.2)', top: '-10px'}}/>} 
                    label={<span style={{ display:'inline]', fontSize: '50px' }}>👨</span>} 
                    classes={{label: classes.label}}
                    style={{display:'inline', fontSize:'50px !important'}}
                  />
                  <span style={{width: '50px'}} />
                  <FormControlLabel 
                    value="f" 
                    control={<Radio style={{color: "#D4AF37", transform: 'scale(1.2)', top: '-10px'}}/>} 
                    label={<span style={{ display:'inline]', fontSize: '50px' }}>👩</span>} 
                    classes={{label: classes.label}}
                    style={{display:'inline', fontSize:'50px !important'}}
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
        <DropzoneArea
        dropzoneParagraphClass={classes.dropzoneText}
        previewGridProps={{
          item: {
            style: {
              justifyContent: 'center',
              width: '100%',
              paddingRight: '0',
              paddingLeft: '0'
            }
          },
          container: {
            style: {
              justifyContent: 'center',
              display: 'flex'
            }
          },
          image: {
            style: {
              maxWidth: '100%',  // Makes image responsive
              height: 'auto'    // Keeps aspect ratio
            }
          }}}
        showAlerts={false}
  acceptedFiles={['image/*']}
  dropzoneText={"בחרי תמונה ברורה שלך ותני לקסם לקרות"}
  onChange={(files) => handleFileChange(files)}
  filesLimit={1}
/>
        </Grid>
      </Grid>

      {isServerError && <Typography variant="caption" style={{display: 'grid', justifyContent: 'center'}}>ממי יש תור... אנחנו לא עומדות בעומס</Typography>}
      {!resultImage && !isLoading && <div style={{display: 'grid'}}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!selectedFile || !gender} style={{ margin: '1em auto', backgroundColor: "#FF69B4"}}>
          סדרי אותי מאמי
        </Button>
      </div>}

      {isLoading && (
        <div style={{ margin: '1em', display: 'grid', placeItems:'center'}}>
          <CircularProgress />
          <Typography variant="caption">טוען...</Typography>
        </div>
      )}

      {resultImage && (
        <ResultImage resultImage={resultImage} onRetry={onRetry}/>
      )}
    </form>
  );
};

export default UploadForm;
