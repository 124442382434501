import React from 'react';
import { Card, CardMedia, Button, CardContent, Typography } from '@mui/material';
import party from './party_lineup.jpeg';

const eventbuzzLink = "https://eventbuzz.co.il/lp/event/oneg-shabat"

export default () => {
  return (
    <a href={eventbuzzLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <div style={{alignContent:'center', justifyContent: 'center'}}>
    <Card style={{ margin: '2rem auto', direction:"rtl", textAlign:'center', maxWidth: '800px'}}>
      <CardContent>
      <Typography variant='h5' style={{marginTop: '10px', marginBottom: '15px'}}>
      וואו! איזה כיף לך שאת כבר יודעת מה את לובשת למסיבה שלנו ב-14.10.
      </Typography>
      <Button size="large" variant="contained" color="secondary" style={{marginBottom: '10px'}}>
      באה להשוויץ
      </Button>
      <CardMedia
            component="img"
            alt="Image Description"
            image={party}
        />
        <Button size="large" variant="contained" color="secondary" style={{marginTop: '10px'}}>
      לרכישת כרטיס
      </Button>
      </CardContent>
      
    </Card>
    
      
    </div>
    </a>
  );
};